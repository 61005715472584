<script>
import { batchTransferReview } from '@/services/approveManage'
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    result: {
      type: String,
      default: '',
    },
    checkedItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
      form: {
        result: '',
        refuseReason: '',
      },
      dictList: [
        {
          code: '1',
          name: '同意',
        },
        {
          code: '0',
          name: '拒绝',
        },
      ],
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.result) {
          this.form.result = this.result
        } else {
          this.form.result = ''
        }
        this.form.refuseReason = ''
        this.visible = val
      },
      immediate: true,
    },
    visible: {
      handler(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {
    async save(done) {
      const batchReqs = this.checkedItems.map((id) => ({ id, ...this.form }))
      await batchTransferReview({ batchReqs })
      done()
      this.$toast(this.$t('审核成功'))
      this.$emit('confirm')
    },
    async handlerConfirm(action, done) {
      if (action === 'confirm') {
        await this.$refs.formRef.validate().then(
          () => {
            this.save(done)
          },
          () => {
            done(false)
          }
        )
      } else {
        done()
      }
    },
    getData() {
      return {
        result: this.form.result,
        refuseReason: this.form.refuseReason,
      }
    },
  },
}
</script>
<template>
  <van-dialog
    v-model="visible"
    show-cancel-button
    :before-close="handlerConfirm"
    confirmButtonColor="#B9921A"
    :cancel-button-text="$t('取消')"
    :confirm-button-text="$t('确认')"
  >
    <div class="defeat-from">
      <van-form ref="formRef">
        <van-field
          v-model="form.result"
          required
          :label="$t('审核结果')"
          :rules="[{ required: true, message: $t('请选择审核结果') }]"
        >
          <template #input>
            <van-radio-group v-model="form.result" direction="horizontal">
              <van-radio
                v-for="item in dictList"
                :key="item.code"
                :name="item.code"
              >
                {{ item.name }}
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-if="form.result != 1"
          v-model="form.refuseReason"
          required
          show-word-limit
          :label="$t('拒绝原因')"
          :placeholder="$t('请输入拒绝原因')"
          :rules="[{ required: true }]"
          maxlength="200"
          rows="3"
          autosize
          type="textarea"
        />
      </van-form>
    </div>
  </van-dialog>
</template>

<style lang="less" scoped>
.defeat-from {
  padding: 20px;
}
</style>
