import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 审批中心统计
export const reviewCenterCount = (params = {}) => request.get(`${SERVER.STATISTICS_SERVICE}/api/v1/reviewCenter/count`, { params }).then(thenResolve)
// 审批中心统计
export const reviewCenterCountForProtal = (params = {}) => request.get(`${SERVER.STATISTICS_SERVICE}/api/v1/reviewCenter/head-count`, { params }).then(thenResolve)

// 查询待审批列表
export const queryReviewList = (params = {}) => request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/queryReviewList`, params).then(thenResolve)

// 提交转入申请
export const transferApply = async (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/apply`, params).then(thenResolve)

// 转入待审核
export const reviewList = async (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/list`, params).then(thenResolve)

// 转入审核
export const transferReview = async (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/review`, params).then(thenResolve)

// 批量审核线索转入
export const batchTransferReview = async (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/reviewList`, params).then(thenResolve)

// 批量审核线下下单
export const batchOfflineReview = async (params = {}) => request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/reviewList`, params).then(thenResolve)

// 待审核详情
export const detail = async (params = {}) => request.get(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/detail`, { params }).then(thenResolve)

// 订单修改审批列表
export const getOrderPage = (params = {}) => request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/portal/list`, params).then(thenResolve)

// 批量订单修改审批
export const orderUpdateAp = (params = {}) => request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/audit`, params).then(thenResolve)

// 改派
export const dealerTransferCCC = async (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/apply-ccc`, params).then(thenResolve)

// 上门试驾
export const queryTestDrivePage = (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/approval/page`, params).then(thenResolve)

// 上门试驾批量审批
export const testDriveReview = (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/review-batch`, params).then(thenResolve)

// 上门试驾数量统计
export const testDriveCount = (params = {}) => request.get(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/approval/count`, params).then(thenResolve)