<template>
  <div class="clue-reassignment-detail-page">
    <div class="cell">
      线索信息
    </div>
    <van-cell-group
      inset
      class="clue-info"
      @click="$router.push('/clue-incubation/details?id=' + info.leadId)"
    >
      <div class="content">
        <van-cell :border="false">
          <template #title>
            <div class="user-info">
              <span class="van-ellipsis">{{ info.userName }}</span>
              <span
                style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"
              >
              </span>
              <span>
                {{ info.userMobile }}
              </span>
            </div>
          </template>
        </van-cell>
        <van-cell :border="false" style="margin-top: -12px;">
          <template>
            <span>创建时间：</span>
            <span style="color: #969799;">{{ info.createTime }}</span>
          </template>
        </van-cell>
      </div>
      <van-icon name="arrow" />
    </van-cell-group>
    <div class="cell">
      门店信息
    </div>
    <van-cell-group inset>
      <van-cell title="改派前门店" :value="info.orgDealerName" />
      <van-cell
        title="改派后门店"
        :border="false"
        :value="info.targetDealerName"
      />
    </van-cell-group>
    <div class="cell">
      改派原因
    </div>
    <van-cell-group inset>
      <van-field
        readonly
        :value="getReason()"
        rows="2"
        autosize
        type="textarea"
      />
    </van-cell-group>
    <div class="cell">
      改派描述
    </div>
    <van-cell-group inset>
      <van-field
        readonly
        :value="info.remark"
        rows="3"
        autosize
        type="textarea"
      />
    </van-cell-group>
    <template v-if="info.attachUrl">
      <div class="cell">
        补充材料
      </div>
      <van-cell-group inset style="padding: 10px 8px 2px 16px">
        <van-uploader
          :fileList="
            info.attachUrl.split(',').map((url) => ({ url, isImage: true }))
          "
          :deletable="false"
          :show-upload="false"
        />
      </van-cell-group>
    </template>
    <div v-if="info.status == '1028015'" class="actions">
      <van-button round @click="onReview('0')">
        {{ $t("拒绝") }}
      </van-button>
      <van-button type="info" round color="#EED484" @click="onReview('1')">
        {{ $t("同意") }}
      </van-button>
    </div>
    <ReviewFrom
      v-model="visible"
      :checkedItems="[info.id]"
      :result="result"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import ReviewFrom from './reviewFrom.vue'
import { detail } from '@/services/approveManage'

export default {
  components: {
    ReviewFrom,
  },
  data() {
    return {
      result: '',
      visible: false,
      info: {},
    }
  },
  created() {
    detail({ id: this.$route.query.id }).then((res) => {
      this.info = res
    })
  },
  methods: {
    getReason() {
      const { transferReason = '' } = this.info
      const arr = transferReason.split(',')
      const brr = []
      const array = this.$store.getters.getDictHash(7001)
      for (let index = 0; index < array.length; index++) {
        const { code, name } = array[index]
        if (arr.includes(code)) {
          brr.push(name)
          if (arr.length === brr.length) {
            return brr.join()
          }
        }
      }
      return brr.join()
    },
    onConfirm() {
      this.visible = false
      this.$router.back()
    },
    onReview(result) {
      this.result = result
      this.visible = true
    },
  },
}
</script>
<style lang="less" scoped>
.clue-reassignment-detail-page {
  .clue-info {
    display: flex;
    align-items: center;
    .content {
      flex: 1;
    }
    i {
      margin-right: 16px;
    }
  }
  .cell {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 16px;
    &::before {
      content: "";
      background: #1989fa;
      width: 2px;
      height: 10px;
      margin-right: 4px;
      border-radius: 1px;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .actions {
    padding: 16px;
    display: flex;
    justify-content: space-evenly;
    button {
      width: 40%;
    }
  }
}
</style>
